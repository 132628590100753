@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout-sider-zero-width-trigger {
  display: none;
}

.blurred-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 999;
}

.page {
  /* height: 100%; */
  width: 100%;
  background-color: #add8e6;
}
.page-header {
  font-size: 20px;
  font-weight: bold;
  padding-left: 12px;
  height: 56px;
  background-color: #ffffff;
  line-height: 56px;
  span {
    &:hover {
      cursor: pointer;
    }
  }
}

.page-container {
  /* height: calc(100% - 56px); */
  padding: 12px;
  .page-content {
    height: 100%;
    border-radius: 8px;
    padding: 12px;
    background-color: #ffffff;
    .gap-1 {
      gap: 1rem;
    }
  }
}

.tabs-container {
  background-color: transparent;
}

.ant-tabs-nav {
  margin-bottom: 0;
  margin-bottom: 5px;
  background: rgb(255, 255, 255);
  padding: 0px 8px;
  border-radius: 8px;
}

.tab-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  /* .tab-content {
    padding: 20px 30px;
} */
}
